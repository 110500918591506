import { useJSONFetcher } from "@outplayed/json-fetcher";
import { RAC_getWorkingPatch } from "./patches";
import { getImgFromSpritesheet, getJSON } from "../common";
import { getItemData, uggGetItemImg, uggGetSpriteImg } from "../static-url-helpers";

function cleanItemData(data) {
  const clone = JSON.parse(JSON.stringify(data || {}));
  for (let key of Object.keys(clone)) {
    if ("colloq" in clone[key]) {
      delete clone[key]["colloq"];
    }
  }
  return clone;
}

export function RAC_useRiotItems(versions, ornnItems) {
  return ({ itemId, patch, language, skip, ssr, onCompleted, customCacheKey } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const itemsURL = getItemData(riotPatch, language, itemId);
    const _onCompleted = (url, json) => {
      let data = RAC_mergeWithOrnnJson(ornnItems)(cleanItemData(json.data));
      if (ornnItems[itemId]) {
        data = ornnItems[itemId];
      } else if (itemId) {
        data = { [itemId]: json };
      }

      return onCompleted ? onCompleted(url, data) : data;
    };

    return useJSONFetcher(itemsURL, {
      skip,
      ssr,
      onCompleted: _onCompleted,
      customCacheKey
    });
  };
}

export function RAC_useNewRiotItems(versions) {
  return ({ itemId, patch, language, skip, ssr } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const itemsURL = getItemData(riotPatch, language, itemId);
    const onCompleted = (url, json) => {
      if (itemId) {
        return { [itemId]: json };
      }

      return cleanItemData(json.data);
    };

    return useJSONFetcher(itemsURL, { skip, ssr, onCompleted });
  };
}

export function RAC_useRiotMythicItems(versions, ornnItems) {
  return ({ patch, skip, ssr } = {}) => {
    const { data, loading, error } = RAC_useRiotItems(versions, ornnItems)({ patch, skip, ssr });
    const mythicItems =
      data &&
      Object.entries(data).reduce((acc, curr) => {
        if (isMythicItem(curr[1])) {
          acc.push(parseInt(curr[0]));
        }
        return acc;
      }, []);

    return { data: mythicItems, loading, error };
  };
}

export function RAC_useRiotBootItems(versions, ornnItems) {
  return ({ patch, skip, ssr } = {}) => {
    const onCompleted = (url, json) => {
      return json &&
        Object.entries(json).reduce((acc, curr) => {
          if (isBootItem(curr[1])) {
            acc.push(parseInt(curr[0]));
          }
          return acc;
        }, []);
    }
    return RAC_useRiotItems(versions, ornnItems)({
      patch,
      skip,
      ssr,
      customCacheKey: "boot_items_" + patch,
      onCompleted,
    });
  };
}

export function RAC_useRiotCompletedItems(versions, ornnItems) {
  return ({ patch, skip, ssr } = {}) => {
    const { data, loading, error } = RAC_useRiotItems(versions, ornnItems)({ patch, skip, ssr });
    const completedItems =
      data &&
      Object.entries(data).reduce((acc, curr) => {
        const [itemId, itemJSON] = curr;
        if (
          isCompletedItem(itemJSON) ||
          itemJSON?.["into"]?.some((id) => isOrnnItem(data[id])) // if upgrades to Ornn item then is completed item
        ) {
          acc.push(parseInt(itemId));
        }
        return acc;
      }, []);

    return { data: completedItems, loading, error };
  };
}

const itemsCache = {};
export function RAC_getItems(versions) {
  return ({ patch, language } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const cacheKey = `${riotPatch}_${language}`;
    if (!(cacheKey in itemsCache)) {
      let url = getItemData(riotPatch, language);
      const items = getJSON(url);
      if (!items) return null;

      itemsCache[cacheKey] = items.data;
    }
    return itemsCache[cacheKey];
  };
}

export function RAC_getItemImgFromSprite(versions) {
  return (id, { size, patch, optionalData, webp } = {}) => {
    const items = optionalData || RAC_getItems(versions)({ patch });
    if (items && items[id]) {
      const item = items[id];
      const sprite = item.image.sprite;
      const x = item.image.x;
      const y = item.image.y;
      const curPatch = RAC_getWorkingPatch(versions)(patch);
      const spritesheetUrl = uggGetSpriteImg(curPatch, sprite, { webp });

      return getImgFromSpritesheet(spritesheetUrl, x, y, size);
    } else {
      return null;
    }
  };
}

export function RAC_getItemImg(versions) {
  return (id, { patch, optionalData, webp } = {}) => {
    const items = optionalData || RAC_getItems(versions)({ patch });
    if (items && items[id]) {
      const item = items[id];
      const image = item.image.full;
      const curPatch = RAC_getWorkingPatch(versions)(patch);
      return uggGetItemImg(curPatch, image, { webp });
    } else {
      return "";
    }
  };
}

export function RAC_mergeWithOrnnJson(ornnItems) {
  return (data) => {
    const ornnItemArr = Object.entries(ornnItems || {})
      .filter(([itemId, valObj]) => valObj.name && !data[itemId])
      .map(([itemId, valObj]) => {
        return [
          itemId,
          {
            ...valObj,
            image: {
              full: itemId,
              s3Img: true,
            },
          },
        ];
      });

    return {
      ...Object.fromEntries(ornnItemArr),
      ...data,
    };
  };
}

// Deprecated: Mythics Items removed
export function isMythicItem(itemJSON) {
  return false;

  // if (!itemJSON || !itemJSON.description) {
  //   return false;
  // }
  // if (itemJSON.description.includes("<rarityMythic>") || itemJSON.description.includes("Item_Mythic_Passive")) {
  //   return true;
  // } else {
  //   return false;
  // }
}

export function isBootItem(itemJSON) {
  if (!itemJSON) {
    return false;
  }
  if (itemJSON.tags && itemJSON.tags.includes("Boots")) {
    return true;
  } else {
    return false;
  }
}

// Does not check if upgrades to Ornn item
export function isCompletedItem(itemJSON) {
  if (!itemJSON) {
    return false;
  } else if (itemJSON.specialRecipe) {
    // Muramana, Seraph's Embrace, upgraded support items
    return true;
  } else if (itemJSON.gold && itemJSON.gold.base === 0) {
    // Auto upgrade items or items from runes (stopwatch, magical footwear)
    return false;
  } else if (!itemJSON.into && !itemJSON.from) {
    return false;
  } else if (itemJSON.into && itemJSON.into.length > 0) {
    // Item builds into another item
    return false;
  } else if (itemJSON.consumed || (itemJSON.tags && itemJSON.tags.includes("Consumable"))) {
    return false;
  } else {
    return true;
  }
}

export function isOrnnItem(itemJSON) {
  return "requiredAlly" in itemJSON || itemJSON["description"].includes("ornnBonus");
}

export function RAC_getNormalizedItemNameFromId(versions) {
  return (id, { optionalData, patch } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const data = optionalData || RAC_getItems(versions)({ patch: riotPatch });
    const normalizedName = data[id]["name"].replace(/'/g, "").replace(/ /g, "-").toLowerCase();
    return normalizedName;
  };
}

export function RAC_getItemIdFromName(versions) {
  return (name, { optionalData, patch } = {}) => {
    const riotPatch = RAC_getWorkingPatch(versions)(patch);
    const data = optionalData || RAC_getItems(versions)({ patch: riotPatch });
    const ids = Object.keys(data || {});
    for (let id of ids) {
      if (data[id]["name"].replace(/'/g, "").replace(/ /g, "-").toLowerCase() === name) {
        return id;
      }
    }
    return null;
  };
}

export function getItemType(itemId, data) {
  const id = parseInt(itemId);
  if (data?.starterItems?.includes(id)) return "Starter";
  if (data?.consumableItems?.includes(id)) return "Consumable";
  if (data?.trinketItems?.includes(id)) return "Trinket";
  if (data?.distributedItems?.includes(id)) return "Distributed";
  if (data?.bootsItems?.includes(id)) return "Boots";
  if (data?.basicItems?.includes(id)) return "Basic";
  if (data?.epicItems?.includes(id)) return "Epic";
  if (data?.legendaryItems?.includes(id)) return "Legendary";
  if (data?.mythicItems?.includes(id)) return "Mythic";
  if (data?.ornnItems?.includes(id)) return "Ornn";
  return "";
}
